import { Box, Button, Flex } from '@chakra-ui/react';
import { Input } from '@shared/components/Input';
import { formatCurrency } from '@shared/utils/format';
import { VoucherWithBalance } from 'components/VoucherWithBalance';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectCheckoutVouchers,
  setIsVoucherModalOpen,
  setUsedVoucherData,
  setVoucherValue,
} from 'store/slices/checkoutVouchers';

interface Props {
  voucher: Vouchers.Voucher;
  handleSelectVoucher: (voucher: null) => void;
  totalAmount: string;
}

const VoucherDetails = ({
  voucher,
  handleSelectVoucher,
  totalAmount,
}: Props) => {
  const { code, currency, available_balance, id } = voucher;

  const dispatch = useAppDispatch();

  const { voucherValue } = useAppSelector(selectCheckoutVouchers);

  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    if (Number(totalAmount) <= Number(available_balance)) {
      dispatch(setVoucherValue(totalAmount));
    } else {
      dispatch(setVoucherValue(available_balance));
    }
  }, [totalAmount, available_balance]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(
      setUsedVoucherData({
        id,
        value: voucherValue,
        available_balance,
      }),
    );
    dispatch(setIsVoucherModalOpen(false));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // allow digits and single dot
    const rgx = /^[0-9]*\.?[0-9]*$/;
    if (value.match(rgx)) {
      dispatch(setVoucherValue(value));
    }

    if (Number(value) > Number(available_balance)) {
      setValidationError(
        'The entered value can not be bigger than your voucher balance',
      );
    } else if (Number(value) > Number(totalAmount)) {
      setValidationError(
        'The entered value can not be bigger than your order amount',
      );
    } else {
      setValidationError('');
    }
  };

  return (
    <Box>
      <Box marginBottom="20px">
        <VoucherWithBalance
          code={code}
          currency={currency}
          balance={available_balance}
        />
      </Box>

      <Box as="form" onSubmit={onSubmit} pt="20px">
        <Input
          label="Confirm Use Value"
          name="voucherValue"
          value={voucherValue}
          onChange={handleChange}
          formControlProps={{ marginBottom: '64px' }}
          placeholder="0.00"
          leftAddon={formatCurrency(currency)}
          error={validationError}
        />
        <Flex columnGap="16px">
          <Box width="50%">
            <Button
              w="full"
              size="lg"
              type="submit"
              isDisabled={
                !voucherValue.length || Boolean(validationError.length)
              }
            >
              Use Voucher
            </Button>
          </Box>
          <Box width="50%">
            <Button
              w="full"
              variant="secondary"
              size="lg"
              onClick={() => {
                handleSelectVoucher(null);
                dispatch(setVoucherValue(''));
              }}
            >
              Cancel
            </Button>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default VoucherDetails;
