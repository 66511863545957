import { createSlice } from '@reduxjs/toolkit';
import {
  deleteAuthTokens,
  getAuthTokens,
  setAuthTokens,
} from '@shared/utils/auth';
import { storage, StorageKeys } from '@shared/utils/storage';
import { AuthApi } from 'api/AuthApi';
import { UserApi } from 'api/UserApi';
import { AppDispatch, AppThunk, RootState } from 'store';

import { clearBasket } from './productModal';

type AuthState = {
  isLoading: boolean;
  user: Authentication.User | null;
  error: Error | null;
  isChecking: boolean;
};

const initialState: AuthState = {
  isLoading: true,
  user: null,
  error: null,
  isChecking: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    requestStart: (state) => {
      state.isLoading = true;
      state.user = null;
      state.error = null;
    },

    authSuccess: (state, action: { payload: Authentication.User }) => {
      state.isLoading = false;
      state.isChecking = false;
      state.user = action.payload;
    },
    authFailure: (state, action: { payload: Error }) => {
      state.isLoading = false;
      state.isChecking = false;
      state.error = action.payload;
    },

    checkStart: (state) => {
      state.isChecking = true;
    },

    reset: (state) => {
      state.isLoading = false;
      state.user = null;
      state.error = null;
      state.isChecking = false;
    },
  },
});

const {
  requestStart,
  authSuccess,
  authFailure,
  checkStart,
  reset,
  setLoading,
} = authSlice.actions;

export const login =
  (email: string, password: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(requestStart());
    try {
      const venue_group = getState().website.websiteData?.venue_group.id;
      if (!venue_group) throw Error('Website data not found');
      const res = await AuthApi.login({
        venue_group,
        email,
        password,
      });
      setAuthTokens(res.access_token, res.refresh_token);
      dispatch(authSuccess(res.user));
      storage.remove(StorageKeys.REFERRER_ID);
    } catch (error: unknown) {
      dispatch(authFailure(error as Error));
    }
  };

export const registration =
  ({
    name,
    email,
    phone,
    password1,
    password2,
  }: Omit<Authentication.RegistrationBody, 'venue_group'>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(requestStart());
    try {
      const venue_group = getState().website.websiteData?.venue_group.id;
      if (!venue_group) throw Error('Website data not found');
      const referrer_id = storage.get(StorageKeys.REFERRER_ID);
      const res = await AuthApi.registration({
        venue_group,
        name,
        email,
        phone,
        password1,
        password2,
        referrer_id: referrer_id || undefined,
      });
      setAuthTokens(res.access_token, res.refresh_token);
      dispatch(authSuccess(res.user));
      storage.remove(StorageKeys.REFERRER_ID);
    } catch (error: unknown) {
      dispatch(authFailure(error as Error));
    }
  };

export const checkAuth = (): AppThunk => async (dispatch) => {
  const { access, refresh } = getAuthTokens();
  if (!access && !refresh) {
    dispatch(reset());
    return;
  }
  dispatch(checkStart());
  try {
    const res = await UserApi.getUser();
    dispatch(authSuccess(res));
    storage.remove(StorageKeys.REFERRER_ID);
  } catch (_) {
    /**
     * Refresh token and try again
     */
    try {
      await AuthApi.refresh();
      const res = await UserApi.getUser();
      dispatch(authSuccess(res));
      storage.remove(StorageKeys.REFERRER_ID);
    } catch (_) {
      deleteAuthTokens();
      dispatch(reset());
    }
  }
};

export const updateAuthUser =
  (data: Authentication.UserPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await UserApi.updateUser(data);
      dispatch(authSuccess(res));
    } catch (error: unknown) {
      dispatch(authFailure(error as Error));
    }
  };

export const logout = () => (dispatch: AppDispatch) => {
  deleteAuthTokens();
  dispatch(reset());
  dispatch(clearBasket());
  storage.remove(StorageKeys.PHONE);
};

export const clearAuthData = () => (dispatch: AppDispatch) => {
  dispatch(reset());
};

export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
