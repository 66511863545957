import { useTheme } from '@chakra-ui/react';
import { MarkerClusterer, Renderer } from '@googlemaps/markerclusterer';
import { getGoogleApiKey } from '@shared/utils/getEnvData';
import { useCallback, useRef, useState } from 'react';

import {
  CarrierIcon,
  ClusterIcon,
  DestinationIcon,
  EventIcon,
  OrderIcon,
  OriginIcon,
} from '../assets/icons/googleMapsIcons';
import { CLUSTERER_COLORS, CLUSTERER_LABELS } from '../constants/google';
import { Theme } from '../theme';
import { getColorByIndex } from '../utils/color';
import { areObjectsEquals, deepObjectCopy } from '../utils/object';

type Icons = Record<
  'origin' | 'destination' | 'carrier' | 'order' | 'event',
  | ((color?: string, text?: string, textColor?: string) => google.maps.Icon)
  | null
>;

type MarkerClusterersType = Record<keyof Icons, MarkerClusterer>;

type MarkerData = {
  id: string; //unique key to save markers
  label?: string;
  iconText?: string;
  color?: string;
  textColor?: string;
  onClick?: VoidFunction;
  position?: google.maps.LatLngLiteral;
  listener?: google.maps.MapsEventListener;
  zIndex?: number;
  opacity?: number;
  tooltip?: string;
  infoWindowListener?: {
    openInfoWindow: google.maps.MapsEventListener;
    closeInfoWindow: google.maps.MapsEventListener;
  };
};

type Markers = Record<
  keyof Icons,
  Map<string, { marker: google.maps.Marker; data: MarkerData }>
>;

type PolygonAreaData = {
  id: string;
  geoJsonData: MapView.MapViewVenueConfig['delivery_areas'];
};

type MapStyles = {
  featureType?: string;
  elementType?: string;
  stylers: { [key: string]: string }[];
}[];

const addColorToGeoJsonData = (
  geoJsonData: MapView.MapViewVenueConfig['delivery_areas'],
  color: string,
) => {
  const copiedData = deepObjectCopy(geoJsonData);

  copiedData.features.forEach((feature) => {
    // Add or update the color property in the properties object
    feature.properties.color = color;
  });
  return copiedData;
};

/**
 * Utility function with side-effects to re-position markers
 * Repositions the marker if multiple markers have the same position
 */
const repositionMarkers = (
  hash: Record<string, number>,
  position: google.maps.LatLngLiteral,
) => {
  const latLng = `${position?.lat}_${position?.lng}`;

  if (hash[latLng]) {
    // Calculate row and column indices
    const row = Math.floor(hash[latLng] / 2);
    const col = hash[latLng] % 2;

    // Define offset based on row and column indices
    const offsetLat = row * 0.000008;
    const offsetLng = col * 0.000023;

    hash[latLng] += 1;

    if (position) {
      position.lat += offsetLat;
      position.lng += offsetLng;
    }
  } else {
    hash[latLng] = 1;
  }
};

export const useGoogleApi = (withMarkerClusterer = true) => {
  const { colors } = useTheme<Theme>();

  const primaryColor: string = colors.primary[400];
  // icons
  const icons = useRef<Icons>({
    origin: null,
    destination: null,
    carrier: null,
    order: null,
    event: null,
  });

  const [isScriptLoaded, setScriptLoaded] = useState<boolean>(
    Boolean(
      window.google?.maps?.Geocoder &&
        window.google?.maps?.places?.Autocomplete,
    ),
  );

  const scriptElement = useRef<HTMLScriptElement | null>(null);

  const [address, setAddress] = useState<Partial<Addresses.Address>>();

  const findAddressPart = useCallback(
    (place: google.maps.places.PlaceResult, type: string) =>
      (place.address_components ?? []).find((el) => el.types.includes(type))
        ?.long_name,
    [],
  );

  const setupAutoComplete = (
    inputRef: HTMLInputElement,
    countryCodes: string[],
  ) => {
    if (!isScriptLoaded) return;
    const autocomplete = new window.google.maps.places.Autocomplete(inputRef);
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    // up to 5 countries
    autocomplete.setComponentRestrictions({
      country: countryCodes,
    });

    const handleSetPlace = (place: google.maps.places.PlaceResult) => {
      setAddress({
        country: findAddressPart(place, 'country'),
        city: findAddressPart(place, 'locality'),
        eircode: findAddressPart(place, 'postal_code'),
        line_1: [
          findAddressPart(place, 'route'),
          findAddressPart(place, 'street_number'),
        ]
          .filter((item) => item !== null)
          .join(' '),
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
        google_place_id: place.place_id,
        full_address: place.formatted_address,
        formatted_address: place.formatted_address,
        street_number: findAddressPart(place, 'street_number'),
        premise: findAddressPart(place, 'premise'),
      });
    };

    // Add an event listener for 'Enter' event on the input element
    inputRef.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        const query = inputRef.value;
        void autocompleteService.getPlacePredictions(
          { input: query, componentRestrictions: { country: countryCodes } },
          (predictions, status) => {
            if (
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              predictions &&
              predictions.length > 0
            ) {
              const placeId = predictions[0].place_id; // Get the place_id of the first prediction

              // Fetch the details of the selected place using the Place Details service
              const placesService = new google.maps.places.PlacesService(
                document.createElement('div'),
              );
              placesService.getDetails({ placeId }, (result, status) => {
                if (
                  status === google.maps.places.PlacesServiceStatus.OK &&
                  result
                ) {
                  handleSetPlace(result);
                }
              });
            }
          },
        );
      }
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if ('address_components' in place) {
        handleSetPlace(place);
      }
    });
  };

  const map = useRef<google.maps.Map | null>(null);

  //Marker clusters logic
  const rendererClustererMarker = (type: keyof Icons): Renderer => ({
    render: ({ count, position }) =>
      new google.maps.Marker({
        label: { text: String(count), color: 'white', fontSize: '10px' },
        icon: {
          url: `data:image/svg+xml;base64,${ClusterIcon(
            CLUSTERER_COLORS[type],
          )}`,
          scaledSize: new google.maps.Size(45, 45),
        },
        title: `${count} ${CLUSTERER_LABELS[type]}`,
        position,
        // adjust zIndex to be above other markers
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      }),
  });
  const markerClusterers = useRef<MarkerClusterersType | null>(null);

  const initMap = (
    mapElement: HTMLDivElement,
    mapStyles: MapStyles = [],
  ): google.maps.Map | null => {
    if (isScriptLoaded) {
      icons.current = {
        origin: (color?: string) => ({
          url: `data:image/svg+xml;base64,${OriginIcon(color ?? primaryColor)}`,
          anchor: null,
        }),
        destination: (color?: string) => ({
          url: `data:image/svg+xml;base64,${DestinationIcon(
            color ?? primaryColor,
          )}`,
        }),
        carrier: (color?: string, text?: string) => ({
          url: `data:image/svg+xml;base64,${CarrierIcon(
            color ?? primaryColor,
            text,
          )}`,
        }),
        order: (color?: string, text?: string, textColor?: string) => ({
          url: `data:image/svg+xml;base64,${OrderIcon(
            color ?? primaryColor,
            text,
            textColor,
          )}`,
          anchor: null,
        }),
        event: (color?: string) => ({
          url: `data:image/svg+xml;base64,${EventIcon(color ?? primaryColor)}`,
        }),
      };
      map.current = new window.google.maps.Map(mapElement, {
        zoom: 2,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        center: { lat: 0, lng: 0 },
        styles: mapStyles,
      });

      markerClusterers.current = {
        order: new MarkerClusterer({
          map: map.current,
          renderer: rendererClustererMarker('order'),
        }),
        origin: new MarkerClusterer({
          map: map.current,
          renderer: rendererClustererMarker('origin'),
        }),
        carrier: new MarkerClusterer({
          map: map.current,
          renderer: rendererClustererMarker('carrier'),
        }),
        destination: new MarkerClusterer({
          map: map.current,
          renderer: rendererClustererMarker('destination'),
        }),
        event: new MarkerClusterer({
          map: map.current,
          renderer: rendererClustererMarker('event'),
        }),
      };
      return map.current;
    }
    return null;
  };

  // Markers logic
  const markers = useRef<Markers>({
    order: new Map(),
    origin: new Map(),
    carrier: new Map(),
    destination: new Map(),
    event: new Map(),
  });

  const renderInfoWindow = (
    content: string,
    ariaLabel: string,
    marker: google.maps.Marker,
  ) => {
    const infowindow = new google.maps.InfoWindow({
      content,
      ariaLabel,
    });

    const openInfoWindow = marker.addListener('mouseover', () =>
      infowindow.open({
        anchor: marker,
        map: map.current,
      }),
    );

    const closeInfoWindow = marker.addListener('mouseout', () =>
      infowindow.close(),
    );

    return { openInfoWindow, closeInfoWindow };
  };

  const setBaseMarkers = useCallback(
    (data: MarkerData[], type: keyof Icons) => {
      // Remove markers that are not present in data
      markers.current[type].forEach((marker, key) => {
        if (!data.some(({ id }) => id === key)) {
          marker.data.listener &&
            google.maps.event.removeListener(marker.data.listener);
          marker.marker.setMap(null); // Remove marker from map
        }
      });

      // Update existing markers with new data
      data.forEach(
        ({ id, label, color, onClick, position, iconText, textColor }) => {
          const existingMarker = markers.current[type].get(id);

          if (existingMarker) {
            existingMarker.marker.setMap(map.current);
            existingMarker.marker.setPosition(position);
            if (existingMarker.data.label !== label) {
              existingMarker.marker.setTitle(label);
            }
            if (
              existingMarker.data.color !== color ||
              existingMarker.data.label !== label ||
              existingMarker.data.iconText !== iconText
            ) {
              existingMarker.marker.setIcon(
                icons.current[type]?.(color, iconText ?? label, textColor),
              );
            }
            existingMarker.data.listener &&
              google.maps.event.removeListener(existingMarker.data.listener);
            const listener =
              onClick && existingMarker.marker.addListener('click', onClick);
            existingMarker.data = {
              id,
              label,
              color,
              onClick,
              position,
              listener,
              iconText,
              textColor,
            };
          }
        },
      );

      // Add new items that are present in data but not in markers
      data.forEach(
        ({ id, label, color, onClick, position, iconText, textColor }) => {
          if (!markers.current[type].has(id)) {
            const marker = new google.maps.Marker({
              position,
              map: map.current,
              icon: icons.current[type]?.(color, iconText ?? label, textColor),
              zIndex: 1,
              title: label,
            });
            const listener = onClick && marker.addListener('click', onClick);
            markers.current[type].set(id, {
              marker,
              data: {
                id,
                label,
                color,
                onClick,
                position,
                listener,
                iconText,
                textColor,
              },
            });
          }
        },
      );
    },
    [map.current, markers.current],
  );

  const setMarkers = useCallback(
    (data: MarkerData[], type: keyof Icons) => {
      const hash = Object.create(null) as Record<string, number>;

      // Remove markers that are not present in data
      markers.current[type].forEach((marker, key) => {
        if (!data.some(({ id }) => id === key)) {
          marker.data.listener &&
            google.maps.event.removeListener(marker.data.listener);
          // hide marker logic
          if (withMarkerClusterer) {
            markerClusterers.current?.[type].removeMarker(marker.marker, true);
          } else {
            marker.marker.setMap(null);
          }
        }
      });

      data.forEach(
        ({
          id,
          label,
          iconText,
          textColor,
          color,
          onClick,
          position,
          zIndex = 1,
          opacity = 1,
          tooltip,
        }) => {
          const existingMarker = markers.current[type].get(id);
          position && repositionMarkers(hash, position);
          // Update existing markers with new data
          if (existingMarker) {
            existingMarker.marker.setPosition(position);

            if (existingMarker.data.label !== label) {
              existingMarker.marker.setTitle(label);
            }

            if (
              existingMarker.data.color !== color ||
              existingMarker.data.label !== label ||
              existingMarker.data.iconText !== iconText
            ) {
              existingMarker.marker.setIcon(
                icons.current[type]?.(color, iconText ?? label, textColor),
              );
            }

            if (existingMarker.data.opacity !== opacity) {
              existingMarker.marker.setOpacity(opacity);
            }

            existingMarker.data.listener &&
              google.maps.event.removeListener(existingMarker.data.listener);
            const listener =
              onClick && existingMarker.marker.addListener('click', onClick);

            // re-set info window listener if tooltip changed
            let infoWindowListener = existingMarker.data.infoWindowListener;
            if (tooltip !== existingMarker.data.tooltip) {
              existingMarker.data.infoWindowListener &&
                google.maps.event.removeListener(
                  existingMarker.data.infoWindowListener.openInfoWindow,
                );

              existingMarker.data.infoWindowListener &&
                google.maps.event.removeListener(
                  existingMarker.data.infoWindowListener.closeInfoWindow,
                );
              infoWindowListener = tooltip
                ? renderInfoWindow(tooltip, label ?? '', existingMarker.marker)
                : undefined;
            }

            // update data in existed marker
            existingMarker.data = {
              id,
              label,
              iconText,
              textColor,
              color,
              onClick,
              position,
              listener,
              infoWindowListener,
              tooltip,
              zIndex: existingMarker.data.zIndex,
              opacity,
            };

            // display marker logic
            if (withMarkerClusterer) {
              markerClusterers.current?.[type].addMarker(
                existingMarker.marker,
                true,
              );
            } else {
              existingMarker.marker.setMap(map.current);
            }
          } else {
            // Add new items that are present in data but not in markers
            const marker = new google.maps.Marker({
              map: map.current,
              position,
              icon: icons.current[type]?.(color, iconText ?? label, textColor),
              title: label,
              zIndex,
              opacity,
            });

            const listener = onClick && marker.addListener('click', onClick);
            const infoWindowListener = tooltip
              ? renderInfoWindow(tooltip, label ?? '', marker)
              : undefined;

            markers.current[type].set(id, {
              marker,
              data: {
                id,
                label,
                iconText,
                textColor,
                color,
                onClick,
                position,
                listener,
                infoWindowListener,
                tooltip,
                zIndex,
                opacity,
              },
            });

            if (withMarkerClusterer) {
              markerClusterers.current?.[type].addMarker(marker, true);
            }
          }
        },
      );

      if (withMarkerClusterer) {
        markerClusterers.current?.[type].render();
      }
    },
    [map.current, markers.current, markerClusterers.current],
  );

  const loadScript = useCallback(() => {
    const src = `https://maps.googleapis.com/maps/api/js?key=${getGoogleApiKey()}&libraries=places&language=en&loading=async&callback=initMap`;
    const dupScript = document.querySelector(`script[src="${src}"]`);
    if (!dupScript) {
      scriptElement.current = document.createElement('script');
      scriptElement.current.defer = true;
      scriptElement.current.async = true;
      scriptElement.current.src = src;
      document.body.appendChild(scriptElement.current);
    }
    window.initMap = () => void 0;

    const interval = setInterval(() => {
      const isLoaded = Boolean(
        window.google?.maps?.Geocoder &&
          window.google?.maps?.places?.Autocomplete,
      );
      setScriptLoaded(isLoaded);
      if (isLoaded) {
        clearInterval(interval);
      }
    }, 10);
  }, []);

  const updateMapFocus = (
    positions: google.maps.LatLngLiteral[],
    padding = 0,
  ) => {
    if (!positions || positions.length < 1 || !map.current) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();

    positions.forEach((position) => {
      bounds.extend(position);
    });

    map.current.fitBounds(bounds, padding);
  };
  // Polygon area logic
  const features = useRef<
    Map<string, { features: google.maps.Data.Feature[]; data: PolygonAreaData }>
  >(new Map());

  const addPolygonArea = (
    geoJsonData: MapView.MapViewVenueConfig['delivery_areas'],
  ) => {
    if (!map.current) return [];

    return map.current.data.addGeoJson(
      addColorToGeoJsonData(
        geoJsonData,
        getColorByIndex(features.current.size),
      ),
    );
  };

  const setPolygonAreas = (
    datas: PolygonAreaData[],
    colors?: { fillColor?: string; strokeColor?: string },
  ) => {
    if (!map.current) return;

    Array.from(features.current.entries()).forEach(([key, values]) => {
      if (!datas.some(({ id }) => id === key)) {
        values.features.forEach((feature) => map.current?.data.remove(feature));
      }
    });

    datas.forEach(({ id, geoJsonData }) => {
      const existed = features.current.get(id);
      if (existed) {
        // update existing - only check if geoJsonData is changed
        if (areObjectsEquals(existed.data.geoJsonData, geoJsonData)) {
          // nothing changed, just add features back to map
          existed.features.forEach((feature) => map.current?.data.add(feature));
        } else {
          // remove features from the map
          existed.features.forEach((feature) =>
            map.current?.data.remove(feature),
          );
          // set new features and add to map
          features.current.set(id, {
            features: addPolygonArea(geoJsonData),
            data: {
              id,
              geoJsonData,
            },
          });
        }
      } else {
        // add new
        features.current.set(id, {
          features: addPolygonArea(geoJsonData),
          data: {
            id,
            geoJsonData,
          },
        });
      }
    });

    map.current.data.setStyle((feature) => {
      const color = feature.getProperty('color') as string; // Get the color property from the feature

      return {
        fillColor: colors?.fillColor || color || primaryColor,
        strokeColor: colors?.strokeColor || color || primaryColor,
      };
    });
  };

  return {
    isScriptLoaded,
    address,
    setupAutoComplete,
    loadScript,
    initMap,
    updateMapFocus,
    setBaseMarkers,
    setMarkers,
    setPolygonAreas,
  };
};
