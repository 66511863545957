export const getEnvData = (name: string): string => {
  return import.meta.env[name] as string;
};

export const getApiHost = () => getEnvData('VITE_API_HOST');

export const getGoogleApiKey = () => getEnvData('VITE_GOOGLE_API_KEY');

export const getOrderExpireTime = () => getEnvData('VITE_ORDER_EXPIRE_TIME');

export const getWebSocketHost = () => getEnvData('VITE_WEB_SOCKET_HOST');

export const getVivaCheckoutUrl = () => getEnvData('VITE_VIVA_CHECKOUT_URL');

export const getSentryIgnoreErrorListString = () =>
  getEnvData('VITE_SENTRY_IGNORE_ERROR_LIST');
