import { Heading, List, Text } from '@chakra-ui/react';
import { isAfter, isToday } from 'date-fns';
import { format } from 'date-fns-tz';
import { useCallback, useMemo, useState } from 'react';

import { ASAP_TIMESLOT } from '../../constants/common';
import { useIsMobile } from '../../utils/screen';
import { Modal } from '../Modal';
import { DayTabs } from './DayTabs';
import { SlotItem } from './SlotItem';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  selectedTimeSlot: string;
  handleSelectTimeSlot: (timeslot: string) => void;
  timeSlots: string[];
  dayTimeSlots: Venues.VenueDayTimeSlots[];
  isAsapAvailable: boolean;
  timeZone: string;
}

const ScheduleModal = ({
  isOpen,
  onClose,
  selectedTimeSlot,
  handleSelectTimeSlot,
  timeSlots,
  isAsapAvailable,
  dayTimeSlots,
  timeZone,
}: Props) => {
  const isMobile = useIsMobile();

  const [day, setDay] = useState<string>(format(new Date(), 'yyyy-MM-dd'));

  const showDayTabs = useMemo(() => {
    return (
      dayTimeSlots.length > 1 ||
      (dayTimeSlots.length === 1 &&
        isAfter(new Date(dayTimeSlots[0].day), new Date()))
    );
  }, [dayTimeSlots]);

  const onSlotItemClick = useCallback(
    (date: Date | typeof ASAP_TIMESLOT) => () => {
      handleSelectTimeSlot(
        date === 'Now'
          ? date
          : format(date, 'yyyy-MM-dd HH:mm:ssxxx', {
              timeZone,
            }),
      );
    },
    [handleSelectTimeSlot, timeZone],
  );

  const slots = useMemo(() => {
    const items =
      dayTimeSlots.length > 0 &&
      dayTimeSlots.find((item) => item.day === day)?.time_slots;

    const selectedDayTime = selectedTimeSlot.substring(0, 19);

    return (items || timeSlots).map((slot) => (
      <SlotItem
        key={slot}
        selected={selectedDayTime === `${day} ${slot}`}
        onClick={onSlotItemClick(new Date(`${day}T${slot}`))}
      >
        {slot.substring(0, 5)}
      </SlotItem>
    ));
  }, [day, dayTimeSlots, timeSlots, selectedTimeSlot, onSlotItemClick]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ mobile: 'full', desktop: 'sm' }}
      bodyProps={{ pt: isMobile ? '16px' : '24px' }}
    >
      <Heading size="sm" lineHeight="32px" mb="24px">
        Pick a preorder date and time
      </Heading>
      {!showDayTabs && slots.length === 0 ? (
        <Text>
          Unfortunately, there are no available pre-order slots. Come back
          later.
        </Text>
      ) : (
        <>
          {showDayTabs && (
            <DayTabs
              items={dayTimeSlots.map((item) => item.day)}
              selected={day}
              onSelect={setDay}
            />
          )}

          <List mt="12px">
            {isAsapAvailable && isToday(new Date(day)) && (
              <SlotItem
                selected={selectedTimeSlot === ASAP_TIMESLOT}
                onClick={onSlotItemClick(ASAP_TIMESLOT)}
              >
                Now
              </SlotItem>
            )}

            {slots}
          </List>
        </>
      )}
    </Modal>
  );
};

export default ScheduleModal;
