import { useToast } from '@chakra-ui/react';
import { generateOrderItems } from '@shared/utils/product';
import { storage, StorageKeys } from '@shared/utils/storage';
import TagManager from '@sooro-io/react-gtm-module';
import { OrdersApi } from 'api/OrdersApi';
import {
  WIDGET_PLACE_ORDER,
  WIDGET_PROMOTION_REDEEMED,
} from 'constants/googleTagManagerEvents';
import get from 'lodash.get';
import { useCallback, useEffect, useState } from 'react';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Paths } from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearUsedVoucherData } from 'store/slices/checkoutVouchers';
import {
  clearData,
  getOrder,
  selectOrders,
  setError,
} from 'store/slices/orders';
import {
  reorder,
  selectProductModal,
  setReorderResponse,
} from 'store/slices/productModal';
import { clearBasket } from 'store/slices/productModal';
import { selectWebsite } from 'store/slices/website';
import { prepareOrderItems } from 'utils/product';

let wsClose: VoidFunction;

/*
use this as part of the VIVA wallet success redirect url:
.../#/order/details/confirmation?paymentId=1541117060087501
*/
const UNKNOWN_ORDER_ID = 'confirmation';

export const useOrderStatus = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const { order, error } = useAppSelector(selectOrders);

  const { basketData, serviceId, venueId, reorderData } =
    useAppSelector(selectProductModal);
  const { websiteData } = useAppSelector(selectWebsite);

  const { orderId } = useParams<{ orderId: string }>();
  const [search] = useSearchParams();

  const [trackingData, setTrackingData] = useState<
    Orders.TrackingData['payload'] | null
  >(null);
  const [isFromVivaWallet, setIsFromVivaWallet] = useState(false);

  const syncCart = useCallback(
    (orderId: string) => {
      void OrdersApi.syncCart({
        order_id: orderId ?? null,
        venue: venueId,
        venue_group_id: websiteData?.venue_group.id || '',
        service: serviceId,
        website: websiteData?.id || '',
        products: [
          ...reorderData,
          ...generateOrderItems(basketData, prepareOrderItems),
        ],
      });
    },
    [venueId, serviceId, websiteData, basketData, reorderData],
  );

  // after card payment get orderId and redirect to OrderStatus route with orderId param
  useEffect(() => {
    const paymentId = search.get('paymentId');

    if (orderId === UNKNOWN_ORDER_ID && paymentId) {
      OrdersApi.getOrderId('viva_wallet', paymentId)
        .then((res) => {
          setIsFromVivaWallet(true);
          // if order is paid, this syncCard will update marketing platform about it
          syncCart(res.order_id);
          dispatch(clearUsedVoucherData());
          dispatch(clearBasket());

          storage.remove(StorageKeys.DRAFT_ORDER_ID);

          navigate(
            generatePath(Paths.OrderStatus, {
              orderId: res.order_id,
            }),
            {
              replace: true,
            },
          );
        })
        .catch((error: Error) => {
          dispatch(setError(error));
          toast({
            description: error.message || String(error),
            status: 'error',
            isClosable: true,
          });
        });
    }
  }, [search, orderId]);

  // send the data to the TagManager
  useEffect(() => {
    // fire once after viva success redirect
    if (isFromVivaWallet && order) {
      setIsFromVivaWallet(false);

      const orderDiscounts = get(order, 'order_discounts');

      let discountsTotal = 0;

      if (orderDiscounts && orderDiscounts.length) {
        discountsTotal = orderDiscounts.reduce(
          (acc, item) => acc + +item.total_amount,
          0,
        );
      }

      const hasCouponDiscount = orderDiscounts.find(
        (discount) => discount.discount_type === 0,
      );

      if (hasCouponDiscount) {
        TagManager.dataLayer({
          dataLayer: {
            event: WIDGET_PROMOTION_REDEEMED,
          },
        });
      }

      TagManager.dataLayer({
        dataLayer: {
          event: WIDGET_PLACE_ORDER,
          order_id: order.id,
          order_value_before_discount: (
            +order.total_amount + discountsTotal
          ).toFixed(2),
          order_value_after_discount: order.total_amount,
          order_currency: order.currency,
        },
      });
    }
  }, [isFromVivaWallet, order]);

  // get order data by id
  useEffect(() => {
    if (orderId && orderId !== UNKNOWN_ORDER_ID) {
      dispatch(
        getOrder(
          {
            orderId,
          },
          false,
        ),
      );
    }

    if (
      orderId &&
      orderId !== UNKNOWN_ORDER_ID &&
      order &&
      order.status === 4 &&
      [2, 4].includes(order.driver_status ?? null) &&
      order.service.service_type === 0 &&
      order.venue.has_delivery_tracking
    ) {
      void OrdersApi.getDriverLocation(orderId).then((res) => {
        setTrackingData(res);
      });

      OrdersApi.trackShipment(
        (data, close) => {
          wsClose = close;
          data && setTrackingData(data.payload);
        },
        orderId,
        false,
      );
    }

    // request each 1 sec while in draft or pending
    const intervalDraft = setInterval(() => {
      if (
        orderId &&
        orderId !== UNKNOWN_ORDER_ID &&
        (order?.status === 0 || order?.status === 10)
      ) {
        dispatch(getOrder({ orderId }, false));
      }
    }, 1000);

    // request each 10 sec for all else "in progress" statuses
    const intervalRest = setInterval(() => {
      if (
        orderId &&
        orderId !== UNKNOWN_ORDER_ID &&
        order?.status !== 0 &&
        order?.status !== 5 && // delivered
        order?.status !== 6 && // collected
        order?.status !== 7 && // completed
        order?.status !== 8 && // cancelled
        order?.status !== 10
      ) {
        dispatch(
          getOrder(
            {
              orderId,
            },
            false,
          ),
        );
      }
    }, 10000);

    // no more draft or pending, stop each second requests
    if (order?.status !== 0 && order?.status !== 10) {
      clearInterval(intervalDraft);
    }

    // stop requests if completed or canceled, nothing to update
    if (
      order?.status === 5 ||
      order?.status === 6 ||
      order?.status === 7 ||
      order?.status === 8
    ) {
      wsClose?.();
      clearInterval(intervalRest);
    }

    return () => {
      wsClose?.();
      clearInterval(intervalDraft);
      clearInterval(intervalRest);
    };
  }, [orderId, order?.status, order?.driver_status]);

  useEffect(() => {
    return () => {
      // clear basket upsells data
      dispatch(clearData());
    };
  }, []);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  return {
    data: {
      order,
      trackingData,
      error,
      websiteEmail: websiteData?.email,
    },
  };
};

export const useReorder = () => {
  const dispatch = useAppDispatch();
  const { reorderResponse, reorderData } = useAppSelector(selectProductModal);
  const toast = useToast();
  const navigate = useNavigate();
  const { orderId } = useParams<{ orderId: string }>();

  useEffect(() => {
    if (reorderResponse === true && reorderData && reorderData.length > 0) {
      dispatch(setReorderResponse(null));

      const venueId = storage.get(StorageKeys.VENUE_ID);
      const serviceId = storage.get(StorageKeys.SERVICE_ID);
      navigate(
        generatePath(Paths.Menu, {
          venueId,
          serviceId,
          department: null,
        }),
      );
    }
    if (
      reorderResponse &&
      (typeof reorderResponse === 'object' || reorderData.length < 1)
    ) {
      toast({
        description:
          reorderResponse && typeof reorderResponse === 'object'
            ? reorderResponse.message || String(reorderResponse)
            : 'Unfortunately these products are no longer available',
        status: 'error',
        isClosable: true,
      });
      dispatch(setReorderResponse(null));
    }
  }, [reorderResponse, reorderData]);

  return () => {
    orderId && dispatch(reorder(orderId));
  };
};
