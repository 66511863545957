import { Flex } from '@chakra-ui/react';
import { DeliveryTrackingMap } from 'components/DeliveryTrackingMap';
import get from 'lodash.get';
import { MENU_HEIGHT } from 'pages/NavigationMenu';

import { useOrderTracking } from './hooks';
import OrderInfo from './OrderInfo';

export const OrderTracking = () => {
  const {
    data: { order, driverData, isOrderFinished },
  } = useOrderTracking();

  const venueLocation: google.maps.LatLngLiteral = {
    lat: get(order, ['venue', 'lat'], 0),
    lng: get(order, ['venue', 'lng'], 0),
  };

  const deliveryLocation: google.maps.LatLngLiteral = {
    lat: get(order, ['delivery_address', 'lat'], 0),
    lng: get(order, ['delivery_address', 'lng'], 0),
  };

  return (
    <Flex
      minH={`calc(100dvh - ${MENU_HEIGHT}px)`}
      position="relative"
      flexDirection="column"
    >
      {order && (
        <>
          <DeliveryTrackingMap
            origin={venueLocation}
            destination={deliveryLocation}
            currentLocation={
              isOrderFinished ? null : driverData?.location || null
            }
            minHeight="100px"
            flexGrow="1"
            flexShrink="1"
          />
          <OrderInfo
            eta={order.eta}
            driverName={order.driver?.name}
            driverStatus={order.driver_status}
            orderStatus={order.status}
            timeZone={order.venue.location.timezone}
            venueName={order.venue.name}
            venuePhone={order.venue.phone}
          />
        </>
      )}
    </Flex>
  );
};
