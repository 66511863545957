import { Box, Flex, Image, Text } from '@chakra-ui/react';
import ProductIcon from '@shared/assets/images/product.svg';
import { PriceText } from '@shared/components/Texts/PriceText';
import { useIsMobile } from '@shared/utils/screen';
import { PointsText } from 'components/Texts/PointsText';
import get from 'lodash/get';
import { memo, useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { openProductModal } from 'store/slices/productModal';

interface Iprops {
  product: Product.RootObject;
  addedToCardProductIds: string[];
}

const UpsellItem = ({ product, addedToCardProductIds }: Iprops) => {
  const dispatch = useAppDispatch();

  const isMobile = useIsMobile();

  const {
    image_thumbnails,
    image,
    name,
    display_name,
    description,
    price,
    points,
    calories,
  } = product;

  const onSelectProduct = useCallback(() => {
    dispatch(openProductModal(product, true));
  }, [product]);

  const isSelected = addedToCardProductIds.includes(product.id);

  return (
    <Flex
      direction={isMobile ? 'row' : 'column'}
      padding={isMobile ? '8px' : '16px'}
      border={Boolean(isSelected) ? '2px solid' : '1px solid'}
      borderColor={Boolean(isSelected) ? 'primary.400' : 'gray.200'}
      cursor="pointer"
      onClick={onSelectProduct}
      borderRadius="12px"
      width={isMobile ? '100%' : '200px'}
      boxSizing="border-box"
      justifyContent={isMobile ? 'flex-start' : 'space-between'}
      alignItems={isMobile ? 'center' : 'start'}
    >
      <Image
        src={get(image_thumbnails, ['medium', 'url'], image)}
        fallbackSrc={ProductIcon}
        alt={display_name ?? name}
        objectFit={image_thumbnails || image ? 'cover' : 'contain'}
        height={isMobile ? '96px' : '168px'}
        width={isMobile ? '96px' : '168px'}
        mb={isMobile ? '0' : '4px'}
      />
      <Box padding={isMobile ? '8px 8px 8px 16px' : '0'}>
        <Text fontSize="14px" lineHeight="20px" fontWeight="600" mb="4px">
          {display_name ?? name}
        </Text>
        <Text
          fontSize="12px"
          lineHeight="16px"
          whiteSpace="break-spaces"
          mb="4px"
          color="gray.400"
        >
          {description}
        </Text>
        {Boolean(+calories) && (
          <Text
            fontSize="12px"
            lineHeight="16px"
            whiteSpace="break-spaces"
            mb="4px"
            color="gray.400"
          >
            {`${calories} cal`}
          </Text>
        )}
        <Flex justifyContent="space-between" columnGap="8px">
          <PriceText price={+price} />
          {Boolean(+points) && <PointsText points={points} />}
        </Flex>
      </Box>
    </Flex>
  );
};

export default memo(UpsellItem);
