import { Box, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';
import ProductIcon from '@shared/assets/images/product.svg';
import { AllergensText } from '@shared/components/Texts/AllergensText';
import { PriceText } from '@shared/components/Texts/PriceText';
import { useIsMobile } from '@shared/utils/screen';
import { PointsText } from 'components/Texts/PointsText';

interface Props {
  name: string;
  price: string;
  points: string;
  description: string;
  imageUrl: string;
  allergens: Allergen[];
  isWithModifiers?: boolean;
}

export const ProductDetail = ({
  isWithModifiers,
  name,
  price,
  points,
  description,
  imageUrl,
  allergens,
}: Props) => {
  const isMobile = useIsMobile();

  return (
    <Flex direction="column" padding={isMobile ? '16px' : '24px'}>
      <Flex
        direction={isWithModifiers ? 'column' : 'row'}
        justify="space-between"
        align={isWithModifiers ? 'center' : 'flex-start'}
        mb="16px"
      >
        <Box>
          <Heading
            size="md"
            mb="8px"
            textAlign={isWithModifiers ? 'center' : undefined}
          >
            {name}
          </Heading>
          <Stack
            direction={isWithModifiers ? 'column' : 'row'}
            spacing="8px"
            align="center"
          >
            <PriceText price={+price} />
            {Boolean(+points) && <PointsText points={points} />}
          </Stack>
        </Box>
        <Image
          src={imageUrl}
          fallbackSrc={ProductIcon}
          alt={name}
          objectFit={imageUrl ? 'cover' : 'contain'}
          height={isWithModifiers ? '272px' : '96px'}
          width={isWithModifiers ? '272px' : '96px'}
          my={isWithModifiers ? '80px' : 0}
          mr={isWithModifiers ? 0 : '32px'}
          borderRadius="md"
        />
      </Flex>

      <Text fontSize="sm" mt="auto" whiteSpace="break-spaces">
        {description}
      </Text>
      {allergens && allergens.length > 0 && <AllergensText items={allergens} />}
    </Flex>
  );
};
