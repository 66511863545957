import { Box, Button, Stack, Text } from '@chakra-ui/react';
import { format, getDay, isToday, isTomorrow, isValid } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { getDayName } from '../../utils/date';

interface Props {
  items: string[];
  selected: string;
  onSelect: (value: string) => void;
}

export const DayTabs = ({ items = [], selected, onSelect }: Props) => {
  const btnRefs = useRef<Record<string, HTMLButtonElement>>({});

  useEffect(() => {
    if (btnRefs.current[selected]) {
      btnRefs.current[selected]?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [selected, btnRefs]);

  const handleSelect = useCallback(
    (value: string) => () => {
      onSelect(value);
    },
    [onSelect],
  );

  const tabs = useMemo(() => {
    return items.map((item, i) => {
      const date = new Date(item);
      if (!isValid(date)) return null;
      const title = isToday(date)
        ? 'Today'
        : isTomorrow(date)
        ? 'Tomorrow'
        : getDayName(getDay(date));
      const subtitle = format(date, 'PP');

      return (
        <Button
          key={`${i}-${item}`}
          ref={(ref) => {
            btnRefs.current[item] = ref as HTMLButtonElement;
          }}
          size="lg"
          h="61px"
          minW="fit-content"
          variant="outline"
          borderColor={selected === item ? undefined : 'gray.200'}
          _hover={{ bgColor: 'transparent' }}
          onClick={handleSelect(item)}
        >
          <Box>
            <Text fontSize="14px" fontWeight={600} textAlign="left">
              {title}
            </Text>
            <Text fontSize="12px" color="gray.400">
              {subtitle}
            </Text>
          </Box>
        </Button>
      );
    });
  }, [items]);

  return (
    <Box
      flex={1}
      sx={{
        '.hide-scrollbar::-webkit-scrollbar': {
          display: 'none',
        },
        '.hide-scrollbar': {
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        },
      }}
    >
      <Stack
        direction="row"
        spacing="8px"
        className="hide-scrollbar"
        overflowX="auto"
      >
        {tabs}
      </Stack>
    </Box>
  );
};
