import { isEmpty } from './string';

export const isValidEmail = (email: string): boolean => {
  const emailRegex =
    /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const validatePhoneNumber = (phone: string): string => {
  const phoneRegex = /^(?:\+?[0-9] ?){6,14}[0-9]$/;

  return isEmpty(phone)
    ? 'Phone number is required'
    : !phoneRegex.test(phone)
    ? 'Phone number is invalid'
    : '';
};

export const isValidPassword = (password: string): boolean => {
  /**
   * must contain at least 1 number and at least 8 characters
   */
  const passRegex = /^(?=.*\d)\S{8,}$/g;
  return passRegex.test(password);
};

export const validateEircode = (eircode: string): string => {
  const value = eircode.trim();

  return value.length
    ? value.length < 3
      ? 'Must not be less than 3 symbols'
      : value.length > 9
      ? 'Must not be more than 9 symbols'
      : ''
    : 'Field is required';
};

export const validateFlatNo = (flatNo?: string, isRequired = false): string => {
  const value = flatNo ? flatNo.trim() : '';

  let error = '';
  if (isRequired && !value.length) {
    error = 'Field is required';
  } else if (value.length > 9) {
    error = 'Must not be greater than 9 symbols';
  }
  return error;
};
