import { getQueryString } from '@shared/utils/requests';
import { getSyncCartUrl } from 'utils/getEnvData';

import { BaseApiService, Handler } from './BaseApiService';

export class OrdersApi {
  public static async getOrder(
    { orderId, params }: Orders.OrderParams,
    authRequired = true,
  ) {
    let path = `orders/${orderId}/`;
    if (params) {
      path = path + '?' + getQueryString(params);
    }

    return await BaseApiService.get<Orders.Order>({
      path,
      authRequired,
    });
  }

  public static async editOrder(id: string, body: Orders.EditOrderBody) {
    return await BaseApiService.patch<Orders.EditOrderBody, unknown>({
      path: `orders/${id}/`,
      body,
    });
  }

  public static async getOrdersHistory({ params }: Orders.OrderHistoryParams) {
    let path = 'orders/';
    if (params) {
      path = path + '?' + getQueryString(params);
    }

    return await BaseApiService.get<Orders.OrdersResponse>({
      path,
    });
  }

  public static async availablePoints(total_amount: string, order: string) {
    return await BaseApiService.post<
      { total_amount: string; order: string },
      Points.AvailablePoints
    >({
      path: 'orders/available-points/',
      body: {
        total_amount,
        order,
      },
    });
  }

  public static async checkout(body: Checkout.RequestBody) {
    return await BaseApiService.post<
      Checkout.RequestBody,
      Checkout.RequestResponse
    >({
      path: 'orders/checkout/?expand=service.config',
      body,
    });
  }

  public static async editOrderCheckout(
    id: string,
    body: Checkout.RequestBody,
  ) {
    return await BaseApiService.patch<
      Checkout.RequestBody,
      Checkout.RequestResponse
    >({
      path: `orders/${id}/checkout/?expand=service.config`,
      body,
    });
  }

  public static async discount(code: string, order: string) {
    return await BaseApiService.post<
      { code: string; order: string },
      Discounts.Discount
    >({
      path: `orders/discount/`,
      body: { code, order },
    });
  }

  public static async pay(orderId: string, body: Payments.PayRequestBody) {
    return await BaseApiService.post<
      Payments.PayRequestBody,
      Payments.PayRequestResponse
    >({
      path: `orders/${orderId}/pay/`,
      body,
      version: 'v2',
    });
  }

  public static trackShipment(
    handler: Handler<Orders.TrackingData>,
    orderId: string,
    authRequired?: boolean,
  ) {
    return BaseApiService.connectWebSocket({
      path: `orders/${orderId}/tracking/ws`,
      authRequired,
      handler,
    });
  }

  public static async getDriverLocation(id: string) {
    return await BaseApiService.get<Orders.TrackingData['payload']>({
      path: `orders/${id}/driver/location/`,
      authRequired: false,
    });
  }

  public static async reorder(orderId: string) {
    return await BaseApiService.get<Orders.ReorderResponse>({
      path: `orders/${orderId}/reorder`,
    });
  }

  public static async review(orderId: string, body: Orders.ReviewRequest) {
    return await BaseApiService.post<Orders.ReviewRequest, Orders.Review>({
      path: `orders/${orderId}/review/`,
      body,
    });
  }

  // authRequired: false,
  public static async syncCart(body: Checkout.SyncCardBody) {
    const syncCartUrl = getSyncCartUrl();
    return await BaseApiService.post<Checkout.SyncCardBody>({
      customUrl: syncCartUrl,
      path: '',
      body,
    });
  }

  // authRequired: false,
  public static async getOrderId(paymentProvider: string, paymentId: string) {
    return await BaseApiService.get<{ order_id: string }>({
      path: `orders/${paymentProvider}/transactions/${paymentId}`,
      authRequired: false,
    });
  }

  public static async getOrderTracking(token: string) {
    return await BaseApiService.get<Orders.OrderTracking>({
      path: `orders/${token}/tracking/details/`,
      authRequired: false,
    });
  }
}
