import { Box, Flex, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { Checkbox } from '@shared/components/Checkbox';
import { PriceText } from '@shared/components/Texts/PriceText';
import { useIsMobile } from '@shared/utils/screen';
import { OrdersApi } from 'api/OrdersApi';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setIsCouponModalOpen,
  setLoading,
  setSelectedCouponCode,
} from 'store/slices/checkoutCoupons';
import { selectCheckoutVouchers } from 'store/slices/checkoutVouchers';
import { selectWebsite } from 'store/slices/website';

import { Coupon } from './Coupon';
import { countCouponDiscount } from './helpers';
import Voucher from './Voucher';

interface Props {
  orderId: string;
  totalAmount: string;
  onDiscount: (discount: {
    amount: number;
    data?: Points.AvailablePoints | Discounts.Discount;
  }) => void;
  isUsePoint: boolean;
  setIsUsePoint: (use: boolean) => void;
  discountData?: Discounts.Discount;
  setDiscountData: (discountData?: Discounts.Discount) => void;
  totalDeposit: number;
}

export const Discounts = ({
  orderId,
  totalAmount,
  onDiscount,
  isUsePoint,
  setIsUsePoint,
  discountData,
  setDiscountData,
  totalDeposit,
}: Props) => {
  const dispatch = useAppDispatch();
  const { usedVoucherData } = useAppSelector(selectCheckoutVouchers);
  const { websiteData } = useAppSelector(selectWebsite);

  const isMobile = useIsMobile();
  const [pointsData, setPointsData] = useState<Points.AvailablePoints>();

  const toast = useToast();

  const totalAmountWithDeposit = (Number(totalAmount) + totalDeposit).toFixed(
    2,
  );

  const onCouponClear = useCallback(() => {
    setDiscountData(undefined);
    onDiscount({ amount: 0 });
    dispatch(setSelectedCouponCode(''));
    dispatch(setIsCouponModalOpen(false));
  }, [onDiscount]);

  useEffect(() => {
    const request = async () => {
      const res = await OrdersApi.availablePoints(
        totalAmountWithDeposit,
        orderId,
      );
      setPointsData(res);
    };
    void request();
    setIsUsePoint(false);
    onCouponClear();
  }, [totalAmountWithDeposit]);

  const onChange = useCallback(
    (value: string[]) => {
      const checked = value[0] === 'point';
      if ((checked && !!discountData) || !!usedVoucherData) {
        toast.closeAll();
        toast({
          description: `You can not use ${
            websiteData?.venue_group?.points_config?.name || 'points'
          } with coupon code applied`,
          position: 'bottom-left',
          isClosable: true,
          status: 'info',
        });
        setIsUsePoint(false);
      } else {
        onDiscount({
          amount: checked ? Number(pointsData?.discount_amount) : 0,
          data: checked ? pointsData : undefined,
        });
        setIsUsePoint(checked);
      }
    },
    [
      discountData,
      onDiscount,
      pointsData,
      usedVoucherData,
      websiteData?.venue_group?.points_config?.name,
    ],
  );

  const onCouponApply = useCallback(
    (coupon: string) => {
      if (isUsePoint) {
        toast.closeAll();
        toast({
          description: `You can not apply coupon code while using ${
            websiteData?.venue_group?.points_config?.name || 'points'
          }`,
          position: 'bottom-left',
          isClosable: true,
          status: 'info',
        });
      } else if (Boolean(usedVoucherData)) {
        toast.closeAll();
        toast({
          description: `You can not use ${
            websiteData?.venue_group?.points_config?.name || 'points'
          } with voucher applied`,
          position: 'bottom-left',
          isClosable: true,
          status: 'info',
        });
      } else {
        dispatch(setLoading(true));
        OrdersApi.discount(coupon, orderId)
          .then((res) => {
            setDiscountData(res);
            onDiscount({
              amount: countCouponDiscount(
                res.discount_type,
                Number(totalAmountWithDeposit),
                Number(res.discount_value),
              ),
              data: res,
            });
            dispatch(setIsCouponModalOpen(false));
          })
          .catch((error: Error) => {
            setDiscountData(undefined);
            toast({
              description: error.message || String(error),
              status: 'error',
              isClosable: true,
            });
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      }
    },
    [
      isUsePoint,
      onDiscount,
      totalAmountWithDeposit,
      usedVoucherData,
      websiteData?.venue_group?.points_config?.name,
    ],
  );

  return (
    <Box paddingTop="16px" paddingBottom="16px">
      {pointsData && Number(pointsData.available_points) > 0 && (
        <Flex
          w="100%"
          justify="space-between"
          align="center"
          marginBottom="24px"
        >
          <Checkbox
            value={isUsePoint ? ['point'] : []}
            onChange={onChange}
            position="start"
          >
            {[
              {
                value: 'point',
                element: (
                  <HStack spacing="8px">
                    <Text fontSize="xs" fontWeight={600}>
                      Use my{' '}
                      {websiteData?.venue_group?.points_config?.name ||
                        'points'}
                    </Text>
                    <Text
                      color="gray.400"
                      fontSize="xs"
                    >{`(You can use ${Number(pointsData.available_points)} ${
                      websiteData?.venue_group?.points_config?.name || 'points'
                    })`}</Text>
                  </HStack>
                ),
              },
            ]}
          </Checkbox>
          {isUsePoint && (
            <PriceText
              price={Number(pointsData.discount_amount)}
              color="gray.400"
              fontSize="xs"
              fontWeight={400}
              negative
            />
          )}
        </Flex>
      )}
      <Stack direction={isMobile ? 'column' : 'row'} spacing="16px">
        <Box width={isMobile || !websiteData?.show_voucher ? '100%' : '50%'}>
          <Flex
            direction={isMobile ? 'column' : 'row'}
            w="100%"
            justify="space-between"
            align="center"
          >
            <Coupon
              isApplied={Boolean(discountData)}
              appliedCode={discountData?.code}
              onCouponApply={onCouponApply}
              onCouponClear={onCouponClear}
              isVoucherUsed={Boolean(usedVoucherData)}
              isPointsUsed={isUsePoint}
            />
          </Flex>
        </Box>

        {websiteData?.show_voucher && (
          <Box width={isMobile ? '100%' : '50%'}>
            <Voucher
              isPointsUsed={isUsePoint}
              hasDiscount={Boolean(discountData)}
              totalAmount={totalAmountWithDeposit}
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
};
