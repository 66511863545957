import {
  Box,
  Flex,
  List,
  ListItem as ChakraListItem,
  ListItemProps,
  Text,
} from '@chakra-ui/react';
import { CounterButton } from '@shared/components/Buttons/CounterButton';
import { ExtraListItem } from '@shared/components/OrderDetailsList/ExtraListItem';
import { PriceText } from '@shared/components/Texts/PriceText';
import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import {
  deleteBasketProductByPositionIds,
  deleteReorderProductByPositionId,
  editBasketProductByPositionIds,
  editReorderProductByPositionId,
} from 'store/slices/productModal';

interface Props {
  item: Orders.OrderItemDetails | Product.RootObject | Product.ReorderProduct;
  currency: string;
}

const orderItemStyle: ListItemProps = {
  marginBottom: '8px',
  _last: { marginBottom: '0' },
};

const basketItemStyle: ListItemProps = {
  py: '12px',
  borderBottom: '1px solid',
  borderBottomColor: 'gray.200',
  _last: { borderBottom: 'none' },
};

function isOrderItemDetails(
  item: Orders.OrderItemDetails | Product.RootObject | Product.ReorderProduct,
): item is Orders.OrderItemDetails {
  return (
    !('position_id' in item) &&
    (item as Orders.OrderItemDetails).product_name !== undefined &&
    (item as Orders.OrderItemDetails).total_amount !== undefined &&
    (item as Orders.OrderItemDetails).extras !== undefined
  );
}

function isReorderProduct(
  item: Orders.OrderItemDetails | Product.RootObject | Product.ReorderProduct,
): item is Product.ReorderProduct {
  return (
    (item as Product.ReorderProduct).position_id !== undefined &&
    (item as Product.ReorderProduct).product_name !== undefined &&
    (item as Product.ReorderProduct).extras !== undefined
  );
}

export const ListItem = ({ item, currency }: Props) => {
  const dispatch = useAppDispatch();

  // isOrder pages: order status, order history
  const isOrder = isOrderItemDetails(item);
  const isReorder = isReorderProduct(item);

  const [count, setCount] = useState<number>(
    !isOrder && !isReorder ? item.amount ?? 0 : item.quantity,
  );
  const [totalPrice, setTotalPrice] = useState<number>(
    !isOrder && !isReorder
      ? count * (item.totalPrice ?? 0)
      : count * Number(item.price),
  );

  const handleCounterChange = useCallback((value: number) => {
    setCount(value);
    if (isReorder) {
      if (value < 1) {
        dispatch(deleteReorderProductByPositionId(item.position_id));
      } else {
        dispatch(editReorderProductByPositionId({ ...item, quantity: value }));
        setTotalPrice(value * Number(item.price));
      }
    } else if (!isOrder) {
      if (value < 1) {
        dispatch(
          deleteBasketProductByPositionIds([item.position_id as string]),
        );
      } else {
        dispatch(editBasketProductByPositionIds({ ...item, amount: value }));
        item.totalPrice && setTotalPrice(value * item.totalPrice);
      }
    }
  }, []);

  const title = useMemo(() => {
    if (isOrder) {
      return `${item.quantity > 1 ? `${item.quantity}x ` : ''}${
        item.product_display_name ?? item.product_name
      }`;
    } else if (isReorder) {
      return item.product_display_name ?? item.product_name;
    } else {
      return item.display_name ?? item.name;
    }
  }, [item]);

  const extraListItemProps = useMemo(() => {
    if (isOrder || isReorder) {
      return (
        item.extras as (Orders.OrderItemExtraDetails | Product.ReorderExtra)[]
      )
        .filter((p: Orders.OrderItemExtraDetails | Product.ReorderExtra) =>
          [0, 3].includes(item.product_type)
            ? p.quantity !== p.default_quantity
            : p.quantity !== 0,
        )
        .map((extra) => ({
          name: extra.product_display_name ?? extra.product_name,
          quantity: extra.quantity,
          defaultQuantity: extra.default_quantity,
          price:
            extra.quantity && extra.quantity - extra.default_quantity > 0
              ? extra.price
              : undefined,
          thirdLevelProducts: extra.extras.flatMap((ex) =>
            ex.quantity === ex.default_quantity
              ? []
              : [
                  {
                    name: ex.product_display_name ?? ex.product_name,
                    price: ex.price,
                    quantity: ex.quantity,
                    defaultQuantity: ex.default_quantity,
                  },
                ],
          ),
        }));
    } else {
      return item.extra_sections.flatMap((es) =>
        es.products
          .filter((p) =>
            [0, 3].includes(item.product_type)
              ? p.amount !== p.default_quantity
              : p.amount !== 0,
          )
          .map((p) => ({
            name: p.display_name ?? p.name,
            quantity: p.amount as number,
            defaultQuantity: p.default_quantity,
            price:
              p.amount && p.amount - p.default_quantity > 0
                ? p.price
                : undefined,
            thirdLevelProducts: p.extra_sections.flatMap((tes) =>
              tes.products.flatMap((tp) =>
                tp.amount === tp.default_quantity
                  ? []
                  : [
                      {
                        name: tp.display_name ?? tp.name,
                        price:
                          tp.amount && tp.amount - tp.default_quantity > 0
                            ? tp.price
                            : undefined,
                        quantity: tp.amount as number,
                        defaultQuantity: tp.default_quantity,
                      },
                    ],
              ),
            ),
          })),
      );
    }
  }, [item]);

  return (
    <ChakraListItem {...(!isOrder ? basketItemStyle : orderItemStyle)}>
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        columnGap="4px"
        width="100%"
      >
        <Box width="100%">
          <Flex marginBottom="4px" alignItems="baseline" width="100%">
            <Text fontWeight="600" flexGrow="1" lineHeight="20px">
              {title}
            </Text>
            {isOrder && (
              <Text
                fontSize="12px"
                lineHeight="20px"
                color="gray.400"
                marginLeft="4px"
              >{`${currency} ${item.total_amount}`}</Text>
            )}
          </Flex>

          <List fontSize="14px" lineHeight="20px">
            {extraListItemProps.map((p, i) => (
              <ExtraListItem
                key={`${p.name}-${i}`}
                currency={currency}
                {...p}
              />
            ))}
          </List>
          {!isOrder && (
            <PriceText price={totalPrice} color="gray.400" fontSize="xs" />
          )}
        </Box>

        {!isOrder && (
          <CounterButton
            count={count}
            onChange={handleCounterChange}
            grayVariant
            minimum={0}
          />
        )}
      </Flex>
    </ChakraListItem>
  );
};
