import { Modal } from '@shared/components/Modal';
import { isWithoutModifiers } from '@shared/utils/product';
import { useIsMobile } from '@shared/utils/screen';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  closeProductModal,
  selectProductModal,
} from 'store/slices/productModal';

import { Customise } from './Customise';
import { ProductWithModifiers } from './ProductWithModifiers';
import { ProductWithoutModifiers } from './ProductWithoutModifiers';

export const ProductDetailsModal = () => {
  const isMobile = useIsMobile();
  const { isOpen, product, customiseExtraSections } =
    useAppSelector(selectProductModal);
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeProductModal());
  }, []);

  if (!product) return null;

  const noModifiers = isWithoutModifiers(product);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      bodyProps={
        (noModifiers || isMobile) && !customiseExtraSections
          ? {
              padding: '16px 0 0 0',
              overflow: isMobile && !noModifiers ? 'hidden' : undefined,
            }
          : { padding: 0 }
      }
      size={
        noModifiers
          ? { desktop: 'md', mobile: 'sm' }
          : { desktop: customiseExtraSections ? 'lg' : '3xl', mobile: 'full' }
      }
      variant={isMobile ? 'drawer' : 'modal'}
      contentProps={
        isMobile && noModifiers ? { borderRadius: '16px 16px 0 0' } : undefined
      }
    >
      {customiseExtraSections ? (
        <Customise
          parentId={customiseExtraSections.parentId}
          productId={customiseExtraSections.productId}
          extraSections={customiseExtraSections.data}
          title={product.display_name ?? product.name}
        />
      ) : noModifiers ? (
        <ProductWithoutModifiers product={product} />
      ) : (
        <ProductWithModifiers product={product} />
      )}
    </Modal>
  );
};
