import { Box, BoxProps } from '@chakra-ui/react';
import { useGoogleApi } from '@shared/hooks/useGoogleApi';
import { createRef, useEffect } from 'react';

export interface DeliveryTrackingMapProps {
  origin: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;
  currentLocation: google.maps.LatLngLiteral | null;
}

export const DeliveryTrackingMap = ({
  origin,
  destination,
  currentLocation,
  ...props
}: DeliveryTrackingMapProps & BoxProps) => {
  const { isScriptLoaded, loadScript, initMap, setMarkers, updateMapFocus } =
    useGoogleApi();

  const mapRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (isScriptLoaded) {
      if (mapRef.current) {
        const map = initMap(mapRef.current);

        if (map) {
          map.setCenter(origin);
          setMarkers([{ id: 'origin', position: origin }], 'origin');
          setMarkers(
            [{ id: 'destination', position: destination }],
            'destination',
          );
        }
      }
    } else {
      loadScript();
    }
  }, [isScriptLoaded, mapRef.current]);

  useEffect(() => {
    if (isScriptLoaded && currentLocation) {
      setMarkers([{ id: 'carrier', position: currentLocation }], 'carrier');
      updateMapFocus([currentLocation, destination]);
    }
  }, [currentLocation, isScriptLoaded]);

  return <Box ref={mapRef} w="100%" minH="300px" {...props}></Box>;
};
