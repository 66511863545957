import {
  Flex,
  useCheckbox,
  useCheckboxGroup,
  UseCheckboxProps,
} from '@chakra-ui/react';
import { FC, Fragment, PropsWithChildren, ReactNode } from 'react';

import { CheckboxIcon } from './CheckboxIcon';

interface Props<WrapperProps, ItemWrapperProps, V> {
  WrapperComponent?: FC<WrapperProps> | typeof Fragment;
  wrapperProps?: Partial<WrapperProps>;
  ItemWrapperComponent?: FC<ItemWrapperProps> | typeof Fragment;
  itemWrapperProps?: Partial<ItemWrapperProps>;
  children: {
    value: V;
    element: ReactNode;
  }[];
  onChange: (value: V[]) => void;
  disableUnchecked?: boolean;
  defaultValue?: V[];
  position?: 'start' | 'end';
  value?: V[];
}

const CustomCheckbox = <WrapperProps, ItemWrapperProps, V>({
  position,
  ...props
}: PropsWithChildren<
  UseCheckboxProps & Pick<Props<WrapperProps, ItemWrapperProps, V>, 'position'>
>) => {
  const { state, getCheckboxProps, getInputProps, htmlProps } =
    useCheckbox(props);

  return (
    <Flex
      as="label"
      cursor={props.isDisabled ? 'not-allowed' : 'pointer'}
      align="center"
      {...htmlProps}
    >
      <input {...getInputProps()} />
      {position === 'end' && props.children}
      <CheckboxIcon
        {...getCheckboxProps()}
        isChecked={state.isChecked}
        iconPosition={position}
      />
      {position === 'start' && props.children}
    </Flex>
  );
};

export const Checkbox = <WrapperProps, ItemWrapperProps, V extends string>({
  WrapperComponent = Fragment,
  wrapperProps,
  ItemWrapperComponent = Fragment,
  itemWrapperProps,
  children,
  onChange,
  disableUnchecked,
  defaultValue,
  position = 'end',
  value,
}: Props<WrapperProps, ItemWrapperProps, V>) => {
  const { value: iValue, getCheckboxProps } = useCheckboxGroup({
    onChange,
    defaultValue,
    value,
  });

  return (
    <WrapperComponent {...(wrapperProps as WrapperProps)}>
      {children.map((item) => (
        <ItemWrapperComponent
          key={item.value}
          {...(itemWrapperProps as ItemWrapperProps)}
        >
          <CustomCheckbox
            {...getCheckboxProps({
              value: item.value,
              isDisabled: !iValue.includes(item.value) && disableUnchecked,
            })}
            position={position}
          >
            {item.element}
          </CustomCheckbox>
        </ItemWrapperComponent>
      ))}
    </WrapperComponent>
  );
};
