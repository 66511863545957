import { Button, Flex, Image } from '@chakra-ui/react';
import { useIsMobile } from '@shared/utils/screen';
import { useNavigateOnLanding } from 'hooks/useNavigateOnLanding';
import { useCallback, useMemo } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setNewAddress,
  setShowDeliveryDetailsSection,
  setShowServicesSection,
} from 'store/slices/uiServices';
import { selectWebsite } from 'store/slices/website';
import { isLandingMode } from 'utils/document';

import { MobileMenu } from './MobileMenu';
import { NavButton } from './NavButton';

export const MENU_HEIGHT = isLandingMode ? 80 : 0;

const ignoreMenuList = [Paths['Voucher'], Paths['VoucherRedeem']];

export const NavigationMenu = () => {
  const { websiteData } = useAppSelector(selectWebsite);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [activeSection, scrollByPath] = useNavigateOnLanding();

  const isShowAbout = useMemo(() => {
    return Boolean(
      (websiteData?.about_sections && websiteData?.about_sections.length > 0) ||
        websiteData?.video_url,
    );
  }, []);

  const isShowVouchers = websiteData?.show_voucher;

  const handleNavigation = useCallback(
    (path: Paths) => () => {
      // clear selected service and related data
      dispatch(setShowDeliveryDetailsSection(false));
      dispatch(setNewAddress(null));
      dispatch(setShowServicesSection(true));

      if (location.pathname === Paths.Home) {
        switch (path) {
          case Paths.Home:
          case Paths.About:
          case Paths.LandingVouchers:
          case Paths.Contact:
            scrollByPath(path);
            break;

          default:
            navigate(path);
            break;
        }
      } else if (
        [
          Paths.Home,
          Paths.About,
          Paths.LandingVouchers,
          Paths.Contact,
        ].includes(path)
      ) {
        navigate(Paths.Home);
        setTimeout(() => {
          scrollByPath(path);
        }, 10);
      } else if (path !== location.pathname) {
        navigate(path);
      }
    },
    [location.pathname],
  );

  const isShowMenu = !ignoreMenuList.some((path) =>
    matchPath(path, location.pathname),
  );

  return (
    <Flex
      align="center"
      position="sticky"
      top={0}
      h={`${MENU_HEIGHT}px`}
      px={isMobile ? '24px' : '32px'}
      bgColor="white"
      zIndex={100}
      borderBottom="1px solid"
      borderBottomColor="gray.200"
    >
      {isMobile && isShowMenu && (
        <MobileMenu
          isShowAbout={isShowAbout}
          isShowVouchers={!!isShowVouchers}
          handleNavigation={handleNavigation}
        />
      )}
      <Flex flex={1}>
        {websiteData?.logo && (
          <Image
            alt="logo"
            src={websiteData.logo}
            h="48px"
            ml={isMobile ? '16px' : 0}
            objectFit="contain"
            cursor="pointer"
            onClick={handleNavigation(Paths.Home)}
          />
        )}
      </Flex>
      {!isMobile && isShowMenu && (
        <Flex h="100%" flex={2} justifyContent="center">
          <NavButton
            onClick={handleNavigation(Paths.Home)}
            selected={activeSection === Paths.Home}
          >
            Home
          </NavButton>
          {isShowAbout && (
            <NavButton
              onClick={handleNavigation(Paths.About)}
              selected={activeSection === Paths.About}
            >
              About
            </NavButton>
          )}
          {isShowVouchers && (
            <NavButton
              onClick={handleNavigation(Paths.LandingVouchers)}
              selected={activeSection === Paths.LandingVouchers}
            >
              Vouchers
            </NavButton>
          )}
          <NavButton
            onClick={handleNavigation(Paths.Contact)}
            selected={activeSection === Paths.Contact}
          >
            Contact
          </NavButton>
        </Flex>
      )}
      <Flex justify="flex-end" flex={1}>
        <Button
          size={isMobile ? 'lg' : 'xl'}
          onClick={handleNavigation(Paths.Order)}
          ml="auto"
        >
          Order online
        </Button>
      </Flex>
    </Flex>
  );
};
