import {
  Box,
  Button,
  Collapse,
  Flex,
  Image,
  Link,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { VenueIcon } from '@shared/assets/icons';
import CallIcon from '@shared/assets/icons/call-2.svg';
import ChevronDownIcon from '@shared/assets/icons/chevron-compact-down.svg';
import ChevronUpIcon from '@shared/assets/icons/chevron-compact-up.svg';
import DeliveryIcon from '@shared/assets/icons/delivery.svg';
import { useIsDesktop, useIsTablet } from '@shared/utils/screen';
import {
  CancelledIcon,
  CompletedIcon,
  DeliveredIcon,
  DeliveringIcon,
  OutsideIcon,
  PendingIcon,
  PreparedIcon,
  PreparingIcon,
  // WaitingIcon,
} from 'assets/icons/statusIcons';
import { addMinutes, format } from 'date-fns';
import { isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useEffect } from 'react';
import { useMemo } from 'react';

import { OrderInfoItem } from '../OrderInfoItem';
import { OrderTitle } from '../OrderTitle';

interface Iprops {
  eta: string;
  driverName: string;
  driverStatus: Orders.DriverStatus;
  venuePhone: string | null;
  venueName: string;
  orderStatus: Orders.Status;
  timeZone: string;
}

const pendingStatuses = [0, 1, 9, 11];

const OrderInfo = ({
  eta,
  driverName,
  venueName,
  venuePhone,
  driverStatus,
  orderStatus,
  timeZone,
}: Iprops) => {
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const [isOpen, onToggle] = useBoolean(false);

  useEffect(() => {
    if (isDesktop && !isOpen) {
      onToggle.on();
    }
  }, [isDesktop, isOpen]);

  const estimateArrival = useMemo(() => {
    const date =
      eta &&
      timeZone &&
      isValid(new Date(eta)) &&
      utcToZonedTime(eta, timeZone);

    return date || null;
  }, [eta, timeZone]);

  // check BOS-1917 for mapping
  const isPending = pendingStatuses.includes(orderStatus);
  const isPreparing =
    orderStatus === 2 ||
    (orderStatus === 4 && ![2, 3, 4].includes(driverStatus));
  const isPrepared = orderStatus === 3;
  // const isWaitingDriver = orderStatus === 4 && driverStatus === 1;
  const isOutForDelivery = orderStatus === 4 && driverStatus === 2;
  const isWaitingOutside = orderStatus === 4 && driverStatus === 4;
  const isDelivered = orderStatus === 5;
  const isCompleted = orderStatus === 7;
  const isCancelled = orderStatus === 8;

  return (
    <Box
      bg="white"
      borderRadius="16px"
      borderBottomLeftRadius={isTablet ? '0px' : '16px'}
      borderBottomRightRadius={isTablet ? '0px' : '16px'}
      position={isTablet ? 'static' : 'absolute'}
      top={isTablet ? 'auto' : '40px'}
      bottom={isTablet ? '0' : 'auto'}
      left={isTablet ? '0' : '40px'}
      width={isTablet ? '100%' : '380px'}
      padding={isTablet ? '0 24px' : '28px 24px 0'}
      paddingBottom={isOpen ? '24px' : '0'}
      boxShadow="0 1px 3px 0px #E4E5E7"
      zIndex="2"
    >
      {isTablet && (
        <Button
          height="28px"
          minHeight="28px"
          width="100%"
          onClick={onToggle.toggle}
          bgColor="#fff"
          _hover={{
            bgColor: '#fff',
          }}
          _focus={{
            bgColor: '#fff',
          }}
        >
          <Image
            src={isOpen ? ChevronDownIcon : ChevronUpIcon}
            height="28px"
            width="60px"
          />
        </Button>
      )}
      <Box marginBottom={isTablet ? '10px' : '23px'}>
        {isPending && (
          <OrderTitle
            title="Pending"
            subtitle="Your order is being processed by the venue."
          >
            <PendingIcon color="primary.400" w="48px" h="48px" />
          </OrderTitle>
        )}

        {isPreparing && (
          <OrderTitle
            title="Preparing your order"
            subtitle="Your order will be shown on the map once your delivery driver leaves the venue."
          >
            <PreparingIcon color="primary.400" w="48px" h="48px" />
          </OrderTitle>
        )}

        {isPrepared && (
          <OrderTitle
            title="Your order is Prepared"
            subtitle="Your order will be shown on the map once your delivery driver leaves the venue."
          >
            <PreparedIcon color="primary.400" w="48px" h="48px" />
          </OrderTitle>
        )}

        {/* {isWaitingDriver && (
          <OrderTitle
            title="Waiting for driver"
            subtitle="Your order will be shown on the map once your delivery driver leaves the venue."
          >
            <WaitingIcon color="primary.400" w="48px" h="48px" />
          </OrderTitle>
        )} */}

        {isOutForDelivery && (
          <OrderTitle
            title="Out for delivery"
            subtitle="Your order is on the way."
          >
            <DeliveringIcon color="primary.400" w="48px" h="48px" />
          </OrderTitle>
        )}

        {isWaitingOutside && (
          <OrderTitle
            title="Waiting outside"
            subtitle="Your driver is waiting outside, please arrange collection."
          >
            <OutsideIcon color="primary.400" w="48px" h="48px" />
          </OrderTitle>
        )}

        {isDelivered && (
          <OrderTitle
            title="Delivered"
            subtitle="Your order has been delivered, enjoy your meal!"
          >
            <DeliveredIcon color="primary.400" w="48px" h="48px" />
          </OrderTitle>
        )}

        {isCompleted && (
          <OrderTitle title="Completed" subtitle="Thank you for ordering.">
            <CompletedIcon color="primary.400" w="48px" h="48px" />
          </OrderTitle>
        )}

        {isCancelled && (
          <OrderTitle
            title="Cancelled "
            subtitle="This order has been cancelled."
          >
            <CancelledIcon color="primary.400" w="48px" h="48px" />
          </OrderTitle>
        )}
      </Box>
      <Collapse in={isOpen}>
        <Flex
          alignItems="center"
          borderTop="1px solid"
          borderColor="gray.200"
          padding={isTablet ? '10px 0' : '23px 0'}
        >
          <Text
            as="span"
            fontSize="14px"
            lineHeight="24px"
            color="gray.400"
            width="40%"
          >
            Estimated arrival
          </Text>
          {estimateArrival && (
            <Text
              as="span"
              fontSize="20px"
              lineHeight="32px"
              fontWeight="600"
              width="60%"
              textAlign="right"
            >{`${format(estimateArrival, 'HH:mm')}-${format(
              addMinutes(estimateArrival, 10),
              'HH:mm',
            )}`}</Text>
          )}
        </Flex>
        <Box
          paddingTop={isTablet ? '10px' : '23px'}
          borderTop="1px solid"
          borderColor="gray.200"
        >
          <Box marginBottom={isTablet ? '10px' : '23px'}>
            <OrderInfoItem title="Venue name" subtitle={venueName}>
              <Box
                display="inline-block"
                padding="16px"
                borderRadius="50%"
                backgroundColor="gray.100"
                verticalAlign="top"
              >
                <VenueIcon color="gray.400" w="100%" />
              </Box>
            </OrderInfoItem>
          </Box>

          <OrderInfoItem title="Driver name" subtitle={driverName}>
            <Box
              display="inline-block"
              padding="16px 13px"
              borderRadius="50%"
              backgroundColor="gray.100"
              verticalAlign="top"
            >
              <Image src={DeliveryIcon} w="100%" alt="driver" />
            </Box>
          </OrderInfoItem>
        </Box>
        {venuePhone && (
          <Box
            borderTop="1px solid"
            borderColor="gray.200"
            paddingTop={isTablet ? '10px' : '23px'}
            marginTop={isTablet ? '10px' : '23px'}
          >
            <OrderInfoItem title="Call restaurant" subtitle={venuePhone} isLink>
              <Link
                href={`tel:${venuePhone}`}
                display="inline-block"
                padding="15px"
                borderRadius="50%"
                backgroundColor="gray.100"
                verticalAlign="top"
                _hover={{ opacity: '0.7' }}
                _focus={{ opacity: '0.7' }}
              >
                <Image src={CallIcon} w="100%" alt="call" />
              </Link>
            </OrderInfoItem>
          </Box>
        )}
      </Collapse>
    </Box>
  );
};

export default OrderInfo;
