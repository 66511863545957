import { v4 } from 'uuid';

export const prepareOrderItems = (
  data: Product.RootObject[],
): Checkout.OrderItem[] =>
  data.map((p) => ({
    position_id: p.position_id ?? p.id,
    product: p.id,
    price: p.price,
    quantity: p.amount ?? p.default_quantity,
    vat_rate: p.vat_rate,
    extra_section_relation_id: p.extra_section_relation_id ?? undefined,
    deposit_return_scheme_amount: p.deposit_return_scheme_amount,
    extras: p.extra_sections.flatMap((es) =>
      es.products.map((sP) => ({
        position_id: sP.id,
        product: sP.id,
        extra_section: es.id,
        price: sP.price,
        vat_rate: sP.vat_rate,
        quantity: sP.amount ?? sP.default_quantity,
        deposit_return_scheme_amount: sP.deposit_return_scheme_amount,
        extras: sP.extra_sections.flatMap((tes) =>
          tes.products.map((tp) => ({
            position_id: tp.id,
            product: tp.id,
            extra_section: tes.id,
            price: tp.price,
            vat_rate: tp.vat_rate,
            quantity: tp.amount ?? tp.default_quantity,
            deposit_return_scheme_amount: tp.deposit_return_scheme_amount,
            extras: [],
          })),
        ),
      })),
    ),
  }));

export const prepareReorderProductsForCheckout = (
  products: Product.ReorderProduct[],
): Product.ReorderProduct[] =>
  products.map((p) => ({
    ...p,
    position_id: v4(),
    id: p.product,
    extras: p.extras.map((ex) => ({
      ...ex,
      position_id: ex.product,
      extras: ex.extras.map((eEx) => ({
        ...eEx,
        position_id: eEx.product,
      })),
    })),
  }));

export const generateOrderedDepartment = (
  ids: string[],
  departments: Departments.Department[],
): Departments.Department => ({
  id: 'order-again',
  name: 'Order Again',
  products: ids.flatMap((id) =>
    departments.flatMap((d) => d.products.filter((p) => p.plu === id)),
  ),
});
